import DataTransferUtil from '../../../utils/data-transfer.util';

export const CHANNEL_COLUMNS = [
  {
    title: '渠道名称',
    key: 'name',
    minWidth: 200,
    fixed: 'left',
  },
  {
    title: '管理员',
    key: 'nick_name',
    minWidth: 160,
  },
  {
    title: '绑定手机号',
    key: 'mobile',
    minWidth: 130,
  },
  {
    title: '渠道类型',
    key: 'level',
    slot: 'level',
    minWidth: 120,
  },
  {
    title: '分成比例',
    key: 'divide_ratio',
    minWidth: 100,
  },
  {
    title: '剩余次数',
    key: 'remain_freq_card',
    minWidth: 100,
  },
  {
    title: '租赁余额 (天)',
    key: 'rental_remain_num',
    minWidth: 120,
  },
  {
    title: '直属上级',
    key: 'parent_name',
    minWidth: 200,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    minWidth: 80,
  },
  {
    title: '创建时间',
    key: 'create_time',
    minWidth: 200,
  },
  {
    title: '操作',
    slot: 'action',
    width: 160,
    align: 'center',
    fixed: 'right',
  },
];

export const ALLOWED_PERMISSION = {
  EDIT: 'business:channel:edit',
  STATUS_TOGGLE: 'business:channel:status-toggle',
  RESET: 'business:channel:reset-pwd',
};

export const CHANNEL_ACTION = {
  CREATE: 'business:channel:add',
  REBIND_CHANNEL: 'business:channel:rebind',
  ...ALLOWED_PERMISSION,
};

export const CHANNEL_ACTION_TEXT = {
  [CHANNEL_ACTION.CREATE]: '新建渠道',
  [CHANNEL_ACTION.REBIND_CHANNEL]: '换绑直属上级',
  [CHANNEL_ACTION.EDIT]: '编辑渠道',
  [CHANNEL_ACTION.STATUS_TOGGLE]: '切换渠道状态',
};

export const STATUS = {
  ALL: 'all',
  ENABLE: 1,
  DISABLE: 0,
};

export const STATUS_TEXT = {
  [STATUS.ALL]: '全部',
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(STATUS_TEXT);

export const LEVEL = {
  ALL: 'all',
  LV1: 1,
  LV2: 2,
  LV3: 3,
};

export const LEVEL_TEXT = {
  [LEVEL.ALL]: '全部',
  [LEVEL.LV1]: '一级渠道',
  [LEVEL.LV2]: '二级渠道',
  [LEVEL.LV3]: '三级渠道',
};

export const LEVEL_OPTIONS = DataTransferUtil.enumTransfer2Options(LEVEL_TEXT);

export const TIPS = {
  NAME: '请输入渠道名称',
  NICKNAME: '请输入渠道管理员',
  MOBILE: '请输入管理员手机号',
  PARENT_ID: '请选择直属上级',
  ADDRESS: '请输入地址',
  EMAIL: '请输入联系人邮箱',
  DIVIDE_RATIO: '请输入分成比例',
  BANK: '请输入开户银行',
  BANK_ACCOUNT: '请输入开户银行账号',
  BANK_BUSINESS_NAME: '请输入企业开户名称',
  REBIND_TYPE: '请选择绑定类型',
  BIND_CHANNEL_ID: '请选择绑定渠道或切换绑定类型',
};

export const REBIND_TYPE = {
  CHANNEL: 'channel',
  PROVIDE: 'provide',
};

export const REBIND_TYPE_TEXT = {
  [REBIND_TYPE.CHANNEL]: '渠道',
  [REBIND_TYPE.PROVIDE]: '运营',
};

export const REBIND_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(REBIND_TYPE_TEXT);

export const PROVIDE_ID = 0;
