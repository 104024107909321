import axios from '@/plugins/axios';
import { LEVEL, STATUS } from './constants';

class StoreManageApi {
  static resetPwd(id) {
    return axios({
      url: `/api/store/${id}/reset_pwd`,
      method: 'post',
      headers: {
        meta: {
          desc: '重置密码',
        },
      },
    });
  }

  static getStores(
    page = 1,
    limit = 10,
    name = '',
    channelId = NaN,
    mobile,
    status = STATUS.ALL,
    parentStoreId = '',
    level = LEVEL.ALL,
  ) {
    return axios({
      url: '/api/store/page',
      data: {
        page,
        limit,
        name,
        channel_id: channelId,
        mobile,
        status,
        level,
        parent_store_id: parentStoreId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店列表',
        },
      },
    });
  }

  static getStoreDetail(storeId = NaN) {
    return axios({
      url: `/api/store/${storeId}/detail`,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店详情',
        },
      },
    });
  }

  static createStore(
    name = '',
    nickName = '',
    mobile = '',
    province = '',
    email = '',
    address = '',
    trainPrice = 0,
    divideRatio = 0,
    bank = '',
    bankAccount = '',
    bankBusinessName = '',
    parentId = NaN,
    channelId = NaN,
  ) {
    return axios({
      url: '/api/store/add',
      data: {
        name,
        nick_name: nickName,
        mobile,
        province,
        email,
        address,
        train_price: trainPrice,
        divide_ratio: divideRatio,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
        parent_id: parentId,
        channel_id: channelId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '创建门店',
        },
      },
    });
  }

  static editStore(
    id = NaN,
    name = '',
    nickName = '',
    mobile = '',
    province = '',
    email = '',
    address = '',
    trainPrice = 0,
    divideRatio = 0,
    bank = '',
    bankAccount = '',
    bankBusinessName = '',
    presetLimit = 10,
    examPresetLimit = 10,
  ) {
    return axios({
      url: '/api/store/edit',
      data: {
        id,
        name,
        nick_name: nickName,
        mobile,
        province,
        email,
        address,
        train_price: trainPrice,
        divide_ratio: divideRatio,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
        preset_limit: presetLimit,
        exam_preset_limit: examPresetLimit,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '编辑门店',
        },
      },
    });
  }

  static getSubStoreOfCurrentUser() {
    return axios({
      url: '/api/store/nextlevel/bylogin/list',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询登录用户的下级门店列表',
        },
      },
    });
  }

  static getStoresByStoreNameOrChannelId(name = '', channelId = NaN) {
    return axios({
      url: '/api/store/list',
      data: { name, channel_id: channelId },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询门店列表',
        },
      },
    });
  }

  static reDistributeParentChannelOrStore(
    subIds = '',
    oldParentId = NaN,
    newParentId = NaN,
    oldChannelId = NaN,
    newChannelId = NaN,
  ) {
    return axios({
      url: '/api/store/rebind',
      data: {
        sub_ids: subIds,
        old_parent_id: oldParentId,
        new_parent_id: newParentId,
        old_channel_id: oldChannelId,
        new_channel_id: newChannelId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '换绑直属上级',
        },
      },
    });
  }

  static getCommonStores(
    storeId = NaN,
  ) {
    return axios({
      url: '/api/store/commonstore/list',
      data: {
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询通用门店列表',
        },
      },
    });
  }

  static getOptometrists(
    storeId = NaN,
  ) {
    return axios({
      url: '/api/sgs/storesgs/list',
      data: {
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询视光师列表',
        },
      },
    });
  }

  static unBindCommonStore(
    storeId = NaN,
    commonStoreId = NaN,
  ) {
    return axios({
      url: '/api/store/commonstore/unbind',
      data: {
        store_id: storeId,
        common_store_id: commonStoreId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '解绑通用门店',
        },
      },
    });
  }

  static unBindOptometrist(
    id = NaN,
    storeId = NaN,
  ) {
    return axios({
      url: `/api/sgs/${id}/unbind`,
      data: { store_id: storeId },
      method: 'post',
      headers: {
        meta: {
          desc: '解绑视光师',
        },
      },
    });
  }

  static toggleStoreStatus(
    storeId = NaN,
    status = STATUS.ALL,
  ) {
    return axios({
      url: `/api/store/${storeId}/status/toggle`,
      data: { status },
      method: 'post',
      headers: {
        meta: {
          desc: '切换渠道状态',
        },
      },
    });
  }

  static getUnbindSgsList(
    keyword = '',
  ) {
    return axios({
      url: '/api/sgs/unbind/list',
      data: {
        keyword,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询未绑定视光师列表',
        },
      },
    });
  }

  static getUnbindStoreList(
    keyword = '',
  ) {
    return axios({
      url: '/api/store/children/bylogin/list',
      data: {
        name: keyword,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询未绑定通用门店列表',
        },
      },
    });
  }

  static bindSgs(
    sgsIds = [],
    storeId = NaN,
  ) {
    return axios({
      url: '/api/sgs/bind',
      data: {
        sgs_ids: sgsIds,
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '绑定视光师',
        },
      },
    });
  }

  static bindStores(
    storeId = NaN,
    commonStoreId = NaN,
  ) {
    return axios({
      url: '/api/store/commonstore/add',
      data: {
        common_store_id: commonStoreId,
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '绑定门店',
        },
      },
    });
  }

  static export({ ...data }) {
    return axios({
      url: '/api/store/export',
      method: 'post',
      data: {
        page: data.page,
        limit: data.limit,
        name: data.name,
        channel_id: data.channelId,
        mobile: data.mobile,
        status: data.status,
        level: data.level,
        parent_store_id: data.parentStoreId,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default StoreManageApi;
