import axios from '@/plugins/axios';
import { LEVEL, STATUS } from './constants';

class ChannelManageApi {
  static resetPwd(id) {
    return axios({
      url: `/api/channel/${id}/reset_pwd`,
      method: 'post',
      headers: {
        meta: {
          desc: '重置密码',
        },
      },
    });
  }

  static getChannels(
    page = 1,
    limit = 10,
    name = '',
    nickName = '',
    channelId = NaN,
    parentChannelId = NaN,
    mobile = '',
    status = STATUS.ALL,
    level = LEVEL.ALL,
  ) {
    return axios({
      url: '/api/channel/page',
      data: {
        page,
        limit,
        name,
        nick_name: nickName,
        channel_id: channelId, // TODO: 无效字段
        parent_channel_id: parentChannelId,
        mobile,
        status,
        level,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询渠道列表',
        },
      },
    });
  }

  static getChannelDetail(id = NaN) {
    return axios({
      url: `/api/channel/${id}/detail`,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询渠道详情',
        },
      },
    });
  }

  static createChannel(
    name = '',
    nickName = '',
    mobile = '',
    email = '',
    address = '',
    divideRatio = 0,
    bank = '',
    bankAccount = '',
    bankBusinessName = '',
    parentId = NaN,
  ) {
    return axios({
      url: '/api/channel/add',
      data: {
        name,
        nick_name: nickName,
        mobile,
        email,
        address,
        divide_ratio: divideRatio,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
        parent_id: parentId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '新建渠道',
        },
      },
    });
  }

  static editChannel(
    id = NaN,
    name = '',
    nickName = '',
    mobile = '',
    email = '',
    address = '',
    divideRatio = 0,
    bank = '',
    bankAccount = '',
    bankBusinessName = '',
  ) {
    return axios({
      url: '/api/channel/edit',
      data: {
        id,
        name,
        nick_name: nickName,
        mobile,
        email,
        address,
        divide_ratio: divideRatio,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '编辑渠道',
        },
      },
    });
  }

  static toggleChannelState(
    id = NaN,
    status = STATUS.ENABLE,
  ) {
    return axios({
      url: `/api/channel/${id}/status/toggle`,
      data: { status },
      method: 'post',
      headers: {
        meta: {
          skipError: true,
          desc: '切换渠道状态',
        },
      },
    });
  }

  static reDistributeParentChannel(
    subIds = '',
    oldParentId = NaN,
    newParentId = NaN,
  ) {
    return axios({
      url: '/api/channel/rebind',
      data: {
        sub_ids: subIds,
        old_parent_id: oldParentId,
        new_parent_id: newParentId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '重新分配上级渠道',
        },
      },
    });
  }

  static getSubChannelOfCurrentUser() {
    return axios({
      url: '/api/channel/nextlevel/bylogin/list',
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询登录用户的下级渠道列表',
        },
      },
    });
  }

  static getChannelsByChannelName(name = '') {
    return axios({
      url: '/api/channel/list',
      data: { name },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询渠道列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/channel/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default ChannelManageApi;
