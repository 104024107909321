import DataTransferUtil from '../../../utils/data-transfer.util';

export const STORE_COLUMNS = [
  {
    title: '门店名称',
    key: 'name',
    minWidth: 100,
    fixed: 'left',
  },
  {
    title: '管理员',
    key: 'nick_name',
    minWidth: 130,
  },
  {
    title: '绑定手机号',
    key: 'mobile',
    minWidth: 130,
  },
  {
    title: '门店类型',
    slot: 'level',
    key: 'level',
    minWidth: 100,
  },
  {
    title: '上级门店',
    key: 'parent_name',
    minWidth: 100,
  },
  {
    title: '归属渠道',
    key: 'channel_name',
    minWidth: 100,
  },
  {
    title: '剩余次数',
    key: 'remain_freq_card',
    minWidth: 100,
  },
  {
    title: '租赁余额 (天)',
    key: 'rental_remain_num',
    minWidth: 120,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    minWidth: 80,
  },
  {
    title: '剩余OCR次数',
    key: 'ocr_remain_num',
    minWidth: 140,
  },
  {
    title: '创建时间',
    key: 'create_time',
    minWidth: 180,
  },
  {
    title: '操作',
    slot: 'action',
    width: 370,
    align: 'center',
    fixed: 'right',
  },
];

export const COMMON_STORE_COLUMNS = [
  {
    title: '通用门店ID',
    key: 'store_id',
    minWidth: 150,
  },
  {
    title: '门店名称',
    key: 'store_name',
    width: 150,
  },
  {
    title: '操作企业',
    key: 'opt_group_name',
    width: 100,
  },
  {
    title: '操作人类型',
    key: 'opt_group_type',
    slot: 'opt_group_type',
    width: 120,
  },
  {
    title: '绑定时间',
    key: 'create_time',
    minWidth: 180,
  },
  {
    title: '操作',
    slot: 'action',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
];

export const OPTOMETRIST_COLUMNS = [
  {
    title: '视光师姓名',
    key: 'nick_name',
    minWidth: 100,
  },
  {
    title: '手机号',
    key: 'mobile',
    minWidth: 120,
  },
  {
    title: '加入时间',
    key: 'bind_time',
    minWidth: 180,
  },
  {
    title: '操作',
    slot: 'action',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
];

export const ALLOWED_PERMISSION = {
  EDIT: 'business:store:edit',
  COMMON_STORE_BIND: 'business:store:commonstore:add',
  COMMON_STORE_UNBIND: 'business:store:commonstore:unbind',
  OPTOMETRIST_BIND: 'account:sgs:bind',
  OPTOMETRIST_UNBIND: 'account:sgs:unbind',
  TOGGLE_STATUS: 'business:store:status-toggle',
};

export const STORE_ACTION = {
  CREATE: 'business:store:add',
  REBIND: 'business:store:rebind',
  EDIT: ALLOWED_PERMISSION.EDIT,
  TOGGLE_STATUS: ALLOWED_PERMISSION.TOGGLE_STATUS,
  COMMON_STORE: 'commonStore',
  OPTOMETRIST: 'optometrist',
  RESET: 'business:store:reset-pwd',
};

export const STORE_ACTION_TEXT = {
  [STORE_ACTION.CREATE]: '新建门店',
  [STORE_ACTION.REBIND]: '换绑直属上级',
  [STORE_ACTION.EDIT]: '编辑',
  [STORE_ACTION.COMMON_STORE]: '通用门店',
  [STORE_ACTION.OPTOMETRIST]: '视光师',
  [STORE_ACTION.TOGGLE_STATUS]: '切换门店状态',
  [STORE_ACTION.RESET]: '重置密码',
};

export const LEVEL = {
  ALL: 'all',
  LV1: 1,
  LV2: 2,
  LV3: 3,
  LV4: 4,
  LV5: 5,
  LV6: 6,
  LV7: 7,
  LV8: 8,
  LV9: 9,
  LV10: 10,
};

export const LEVEL_TEXT = {
  [LEVEL.ALL]: '全部',
  [LEVEL.LV1]: '一级门店',
  [LEVEL.LV2]: '二级门店',
  [LEVEL.LV3]: '三级门店',
  [LEVEL.LV4]: '四级门店',
  [LEVEL.LV5]: '五级门店',
  [LEVEL.LV6]: '六级门店',
  [LEVEL.LV7]: '七级门店',
  [LEVEL.LV8]: '八级门店',
  [LEVEL.LV9]: '九级门店',
  [LEVEL.LV10]: '十级门店',
};

export const STATUS = {
  ALL: 'all',
  ENABLE: 1,
  DISABLE: 0,
};

export const STATUS_TEXT = {
  [STATUS.ALL]: '全部',
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(STATUS_TEXT);

export const REBIND_TYPE = {
  CHANNEL: 'channel',
  PROVIDE: 'provide',
};

export const REBIND_TYPE_TEXT = {
  [REBIND_TYPE.CHANNEL]: '渠道',
  [REBIND_TYPE.PROVIDE]: '门店',
};

export const ZHISHU_TYPE_TEXT = {
  [REBIND_TYPE.CHANNEL]: '渠道',
  [REBIND_TYPE.PROVIDE]: '门店',
};

export const REBIND_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(REBIND_TYPE_TEXT);
export const ZHISHU_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(ZHISHU_TYPE_TEXT);

export const TIPS = {
  NAME: '请输入门店名称',
  NICKNAME: '请输入门店管理员',
  MOBILE: '请输入管理员手机号',
  PROVINCE: '请输入省份',
  PARENT_ID: '请选择直属上级',
  ADDRESS: '请输入地址',
  EMAIL: '请输入联系人邮箱',
  TRAIN_PRICE: '请输入单次训练价格',
  DIVIDE_RATIO: '请输入分成比例',
  BANK: '请输入开户银行',
  BANK_ACCOUNT: '请输入开户银行账号',
  BANK_BUSINESS_NAME: '请输入企业开户名称',
  REBIND_TYPE: '请选择绑定类型',
  BIND_CHANNEL_ID: '请选择绑定渠道',
  BIND_STORE_ID: '请选择绑定门店',
};
export const groupType = {
  sys: '运营',
  channel: '渠道',
  store: '门店',
};
export const cities = ['台湾', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '上海', '重庆', '香港', '澳门'];
